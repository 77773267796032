import { Options, Vue } from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {authService} from "@services/auth.service";
import {configuration} from "@plugins/Config-plugin";

@Options({
    name: "ValidateUserPage",
    components: {}
})
export default class ValidateUserPage extends Vue {
    @Prop()
    token: string;
    loading: boolean = true
    response: any

    get logo(){
        return configuration.appLogo
    }

    async mounted(){
        try {
            await authService.validate(this.token);
            this.response = {
                valid: true,
            }
        } catch (err){
            this.response = {
                valid: false,
                message: err.data?.message
            }
        }
        this.loading = false
    }
}
