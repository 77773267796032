import { Options, Vue } from 'vue-class-component';
import {authService} from "@services/auth.service";
import {Field as VField, Form as VForm} from "vee-validate";
import {VI18nError} from "@components";
import {configuration} from "@plugins/Config-plugin";

interface FormState {
    email: string;
}

@Options({
    name: "LostPasswordPage",
    components: {
        VForm,
        VField,
        VI18nError
    }
})
export default class LostPasswordPage extends Vue {

    emailSent: boolean = false;

    email: string = null;

    get logo(){
        return configuration.appLogo
    }

    async sendEmail({ email }: FormState) {
        try {
            const response = await authService.sendResetPassword({ email })
            this.emailSent = true
        } catch (error) {
            console.debug("error", error);
            this.$errorMessage(this.$t('Operation failed'))
        }
    }
}
