import { Options, Vue } from "vue-class-component";
import {authService} from "@services/auth.service";
import {Field as VField, Form as VForm} from "vee-validate";
import {VI18nError} from "@components";
import {Prop} from "vue-property-decorator";
import {configuration} from "@plugins/Config-plugin";

interface FormState {
    password: string;
    confirm_password: string;
}


@Options({
    name: "ResetPasswordPage",
    components: {
        VForm,
        VField,
        VI18nError
    }
})
export default class ResetPasswordPage extends Vue {
    password: string = null;
    confirm_password: string = null;

    @Prop()
    token: string;

    get logo(){
        return configuration.appLogo
    }

    async save({ password, confirm_password }: FormState) {
        if (!this.checkPassword()) {
            return;
        }

        try {
            const response = await authService.setResetPassword({
                password,
                confirm_password,
                token: this.token
            })
            this.$router.replace({ name: 'login' });
        } catch (error) {
            console.debug("error", error);
            this.$errorMessage(this.$t('Operation failed'))
        }
    }

    private checkPassword() {
        if (((!this.password || this.password === '')
            && (!this.confirm_password || this.confirm_password === ''))
            || (this.password === this.confirm_password)) {
            return true;
        }

        this.$errorMessage(this.$t(`validation.pwd_and_confirm_match`))
        return false;
    }
}
